@font-face {
  font-family: "pixel-font";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nVivM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  font-family: "pixel-font", cursive;
}

html,
body {
  background-color: #333;
  margin: 0;
}

video {
  width: 320px;
  height: 240px;
  border: 1px solid black;
}

#game-container {
  display: inline-block;
}

/* Login form */

#nickname-input {
  text-align: center;
  width: 300px;
  height: 36px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  color: lightslategray;
  border: none;
}
#nickname-input:focus {
  outline-color: grey;
}

/* Connected players list */

#screensharing {
  color: whitesmoke;
  display: inline-block; /* par défaut on cache le screensharing */
  background-color: rgba(224, 224, 224, 0.8);
  padding: 10px;
  border-radius: 10px;
  width: 320px;
  height: 180px; /* h x 1,777 = w*/
}

#players {
  color: whitesmoke;
  display: inline-block;
  background-color: rgba(100, 100, 100, 0.8);
  padding: 10px;
  border-radius: 10px;
}

#players ul {
  list-style-type: none;
}

#players li {
  padding-bottom: 0.6rem;
}

.player:before {
  padding-right: 1rem;
  content: url(../assets/images/characters/face_others.png);
}

.player.me:before {
  content: url(../assets/images/characters/face_player.png);
  padding-right: 1rem;
}

.player:after {
  padding-left: 1rem;
  /* content: url(../assets/images/sound.png); TODO pouvoir mute les autres */
}

.player.muted:after {
  padding-left: 1rem;
  /* content: url(../assets/images/nosound.png); TODO pouvoir mute les autres */
}

/* Chat */

#chat-container {
  width: 400px;
  margin: 0 auto;
  border: solid 1px rgba(200, 200, 200, 0.5);
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 5px;
  overflow: hidden;
}

#chat-container.focused {
  background-color: rgba(100, 100, 100, 0.9);
  border-color: rgba(200, 200, 200, 0.9);
}

#chat-container.focused input {
  background-color: rgba(150, 150, 150, 0.9);
}

#chat-messages {
  height: 180px;
  overflow: auto;
  transform: rotate(180deg);
  direction: rtl;
}

#chat-messages .chat-message {
  font-size: 12px;
  color: whitesmoke;
  text-shadow: 1px 1px 2px grey;
  padding: 4px;
  transform: rotate(180deg);
  direction: ltr;
  word-wrap: break-word;
}

.chat-message {
  text-shadow: 1px 1px 2px grey;
}

.chat-message .chat-message-user {
  color: #ccc;
}

.chat-message .chat-message-warning {
  color: rgb(255, 177, 105);
}

.chat-message .chat-message-server-info {
  color: rgb(74, 230, 142);
}

.chat-message .chat-message-server-important {
  color: rgb(227, 120, 88);
}

#chat-form input {
  width: 100%;
  border: none;
  font-size: 12px;
  color: whitesmoke;
  background-color: rgba(150, 150, 150, 0.5);
  padding: 10px;
}

#chat-form input:focus {
  outline: none;
}

/* Drawing board */
#canvas {
  background-color: white;
  border: 3px solid black;
}

#user-drawing {
  position: absolute; margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border: 3px solid black;
  background-color: white;
}

.pixel-button {
  position: relative;
  width: 5em;
  height: 5em;
  float: left;
  margin: 0 0.625em;
  outline: solid 0.25em #000;
  box-shadow: inset 0.313em 0.313em 0 rgba(0, 0, 0, 0.4),
    inset -0.313em -0.313em 0 rgba(255, 255, 255, 0.4),
    0 1em 0.625em -0.4em rgba(0, 0, 0, 0.8);
  transition: linear 0.2s;
  background: #F5CDB6; padding: .5em 0 0 .5em;
}

.pixel-button:hover {
  box-shadow: inset 0.313em 0.313em 0 rgba(0, 0, 0, 0.8),
    inset -0.313em -0.313em 0 rgba(255, 255, 255, 0.6),
    0 1em 0.625em -0.4em rgba(255, 255, 255, 0.8);
}

.pixel-button:before {
  content: " ";
  position: absolute;
  top: -0.25em;
  left: -0.25em;
  width: 0.625em;
  height: 0.625em;
  background: #1b1b1b;
  border-bottom: solid 0.25em #000;
  border-right: solid 0.25em #000;
}

.pixel-button:after {
  content: " ";
  position: absolute;
  bottom: -0.25em;
  right: -0.25em;
  width: 0.625em;
  height: 0.625em;
  background: #1b1b1b;
  border-top: solid 0.25em #000;
  border-left: solid 0.25em #000;
}
